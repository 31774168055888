export default {
  name: "WarehouseDetailsComponent",
  data() {
    return {
      iCurrentPage: 1,
      iItemsPerPage: 5,
      iNumPages: 0,
      tabs: null,
      screenHeight: 0,
      model: null,
      texts: "",
      warehouseName: "",
      warehouseDescription: "",
      wareHouseDetailArr: "",
      documentDetailArr: [],
      isMobile: false,
      isTablet: false,
      isFixed: false,
      isCenter: false,
      imgWidthResponive: false,
      isMobileOrIsTablet: false,
      screenWidth: 0,
      screenHeightMap: false,

      //VARIABLES
      modifyWarehouse: false,
      itemsCountries: [],
      itemsStates: [],
      screenWidth: 0,
      bLoading: false,
      warehouseName: "",
      wahrehouseAddress: "",
      warehouseCountry: "",
      warehouseState: "",
      warehouseStateId: "",
      warehouseCity: "",
      warehousePostalCode: "",
      warehousePhoneNumber: "",
      warehouseEmail: "",
      warehousePrice: "",
      warehouseCustomsPrice: "",
      reponsableWarehouse: "",
      warehouseLatitude: "",
      warehouseLongitude: "",
      warehouseShippingLimitTime: "08:00",
      // --- Schedules --- //
      switchMonday: true,
      warehouseMondayFrom: "",
      warehouseMondayTo: "",
      switchTuesday: true,
      warehouseTuesdayFrom: "",
      warehouseTuesdayTo: "",
      switchWednesday: true,
      warehouseWednesdayFrom: "",
      warehouseWednesdayTo: "",
      switchThursday: true,
      warehouseThursdayFrom: "",
      warehouseThursdayTo: "",
      switchFriday: true,
      warehouseFridayFrom: "",
      warehouseFridayTo: "",
      switchSaturday: true,
      warehouseSaturdayFrom: "",
      warehouseSaturdayTo: "",
      switchSunday: true,
      warehouseSundayFrom: "",
      warehouseSundayTo: "",
      markerWarehouse: {},

      center: { lat: 37.09024, lng: -95.712891 },
      currentPlace: null,
      oPlace: null,
      markers: [],
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 10,
        minZoom: 3,
        componentRestrictions: {
          country: ["us", "mx"],
        },
      },
    };
  },
  beforeMount() {
    this.texts = FILE.warehouseTexts[this.selectLanguage];
    this.$store.commit("setMenuSidebar", { active: false });

    this.warehousesDetail();
    this.documentDetail();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setPlace: function(place) {
      let split_name = place.formatted_address.split(",");
      let name_address = split_name[0] + "," + split_name[1];
      this.wahrehouseAddress = name_address;
      this.currentPlace = place;
      this.oPlace = place;

      this.addMarker();
      this.exampleFill(place);
    },
    addMarker: function() {
      this.markers = [];
      if (this.currentPlace) {
        this.warehouseLatitude = this.currentPlace.geometry.location.lat();
        this.warehouseLongitude = this.currentPlace.geometry.location.lng();

        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.center = marker;
        this.currentPlace = null;
      } else {
        this.markers.push({ position: this.markerWarehouse });
        this.center = this.markerWarehouse;
      }
    },
    detailWarehouse: function() {
      this.$store.commit("setDialogAddDocument", {
        active: true,
      });
    },
    editWarehouse: function() {
      // this.bLoading = true;

      const payload = {
        oWarehouse: {
          sName: this.warehouseName,
          sPhoneNumber: this.warehousePhoneNumber,
          sEmail: this.warehouseEmail,
          dPrice: this.warehousePrice,
          dCustomsFee: this.warehouseCustomsPrice,
        },
        oLocation: {
          sLocationAddress: this.wahrehouseAddress,
          sLocationOptionalAddress: this.wahrehouseAddressOptional,
          sLocationCity: this.warehouseCity,
          sLocationStateId: this.warehouseState,
          sLocationZipCode: this.warehousePostalCode,
          dLocationLatitude: this.markers[0].position.lat,
          dLocationLongitude: this.markers[0].position.lng,
        },
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };
      console.log(payload)
      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses/${this.$route.params.id}`,
        payload,
        config
      )
        .then((response) => {
          this.bLoading = false;
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
          this.modifyWarehouse = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getCountries: function() {
      //post credentials and get access token from laravel backend
      DB.get(`${URI}/api/v1/${this.selectLanguage}/locations/countries`, {
        headers: {},
        params: {},
      })
        .then((response) => {
          this.itemsCountries = response.data.results;
          this.getStates();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    selecState: function(e) {
      // this.state = "";
      this.getStates();
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    getStates: function() {
      //post credentials and get access token from laravel backend
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/locations/countries/${this.warehouseCountry}/states`,
        {
          headers: {},
          params: {},
        }
      )
        .then((response) => {
          this.itemsStates = response.data.results;
          if (this.oPlace) {
            const addressComponents = this.oPlace.address_components;
            for (let i = 0; i < addressComponents.length; i++) {
              //administrative_area_level_1 es el type que usa google para los estados
              if (
                addressComponents[i].types.includes(
                  "administrative_area_level_1"
                )
              ) {
                let selectedState = this.itemsStates.find(
                  (s) =>
                    this.normalizeString(s.sName) ===
                    this.normalizeString(addressComponents[i].long_name)
                );
                this.warehouseState = selectedState.sStateId;
              }

              if (addressComponents[i].types.includes("postal_code")) {
                //codigo postal
                this.warehousePostalCode = addressComponents[i].long_name;
              }

              //administrative_area_level_1 es el type que usa google para la ciudad
              if (addressComponents[i].types.includes("locality")) {
                //ciudad
                this.warehouseCity = addressComponents[i].long_name;
              }
            }
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    exampleFill: function(place) {
      if (place.address_components) {
        const addressComponents = place.address_components;
        let selectedCountry;
        const countryAddressComponent = addressComponents.find((adc) =>
          adc.types.includes("country")
        );

        if (countryAddressComponent.short_name === "MX") {
          this.warehouseCountry = "México";
          selectedCountry = this.itemsCountries.find(
            (c) => c.sName === "México" || c.sName === "Mexico"
          );
          //sCountryId
          this.warehouseCountry = selectedCountry.sCountryId;
          this.selecState();
        }
        if (countryAddressComponent.short_name === "US") {
          selectedCountry = this.itemsCountries.find(
            (c) =>
              c.sName === "Estados Unidos de América" ||
              c.sName === "United States of America"
          );
          //sCountryId
          this.warehouseCountry = selectedCountry.sCountryId;
          this.selecState();
        }
      }
    },
    normalizeString: function(str) {
      return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    soloNumbers: function(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    replaceBadInputs: function(val) {
      // Replace impossible inputs as the appear
      val = val.replace(/[^\dh:]/, "");
      val = val.replace(/^[^0-2]/, "");
      val = val.replace(/^([2-9])[4-9]/, "$1");
      val = val.replace(/^\d[:h]/, "");
      val = val.replace(/^([01][0-9])[^:h]/, "$1");
      val = val.replace(/^(2[0-3])[^:h]/, "$1");
      val = val.replace(/^(\d{2}[:h])[^0-5]/, "$1");
      val = val.replace(/^(\d{2}h)./, "$1");
      val = val.replace(/^(\d{2}:[0-5])[^0-9]/, "$1");
      val = val.replace(/^(\d{2}:\d[0-9])./, "$1");
      return val;
    },
    funcKeyUp: function(e) {
      var val = e.target.value;
      var lastLength;
      do {
        // Loop over the input to apply rules repeately to pasted inputs
        lastLength = val.length;
        val = this.replaceBadInputs(val);
      } while (val.length > 0 && lastLength !== val.length);
      return (e.target.value = val);
    },
    funcBlur: function(e) {
      var val = e.target.value;
      val = /^(([01][0-9]|2[0-3])h)|(([01][0-9]|2[0-3]):[0-5][0-9])$/.test(val)
        ? val
        : "";
      return (e.target.value = val);
    },

    deleteWarehouse() {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.warehousedetail.deleteWarehouse.title,
        descipcion: this.texts.warehousedetail.deleteWarehouse.description,
        note: this.texts.warehousedetail.deleteWarehouse.note,
        chkLabel: this.texts.warehousedetail.deleteWarehouse.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses/${this.$route.params.id}`,
        return: true,
        dobleConfirm: true,
      });
    },
    deleteItemDocument(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.warehousedetail.deleteDocument.title,
        descipcion: this.texts.warehousedetail.deleteDocument.description,
        note: this.texts.warehousedetail.deleteDocument.note,
        chkLabel: this.texts.warehousedetail.deleteDocument.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses/${this.$route.params.id}/files/${item.sBorderWarehousesFileId}`,
        return: false,
        dobleConfirm: false,
      });
    },

    handleResize() {
      this.screenHeightMap =
        window.innerWidth > 960 ? "height:100%" : "height:460px";
      this.screenHeight = window.innerHeight - 260;
      this.isMobileOrIsTablet = window.innerWidth < 960;

      this.isFixed = window.innerWidth > 959;
      this.isMobile = window.innerWidth < 600;
      this.isCenter = window.innerWidth > 600 && window.innerWidth < 960;
      this.isTablet = window.innerWidth > 959 && window.innerWidth < 1264;

      if (window.innerWidth > 900) {
        this.screenWidth = window.innerWidth - 115;
      } else {
        this.screenWidth = window.innerWidth - 10;
      }
    },

    warehousesDetail() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses/${this.$route.params.id}`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {},
        }
      )
        .then((response) => {
          this.wareHouseDetailArr = response.data.results;

          this.markerWarehouse = {
            lat: this.wareHouseDetailArr.oLocation.sLatitude,
            lng: this.wareHouseDetailArr.oLocation.sLongitude,
          };
          (this.warehouseName = this.wareHouseDetailArr.oWarehouse.sName),
          (this.wahrehouseAddress = this.wareHouseDetailArr.oLocation.sAddress),
          (this.wahrehouseAddressOptional = this.wareHouseDetailArr.oLocation.sLocationOptionalAddress),
          (this.warehouseCountry = this.wareHouseDetailArr.oLocation.sCountryId),
          (this.warehouseState = this.wareHouseDetailArr.oLocation.sStateId),
          (this.warehouseStateId = this.wareHouseDetailArr.oLocation.sStateId);
          (this.warehouseCity = this.wareHouseDetailArr.oLocation.sCity),
          (this.warehousePostalCode = this.wareHouseDetailArr.oLocation.sZipCode),
          (this.warehousePhoneNumber = this.wareHouseDetailArr.oWarehouse.sPhoneNumber),
          (this.warehouseEmail = this.wareHouseDetailArr.oWarehouse.sEmail),
          (this.warehousePrice = parseFloat(this.wareHouseDetailArr.oWarehouse.dPrice)),
          (this.warehouseCustomsPrice = parseFloat(this.wareHouseDetailArr.oWarehouse.dCustomsFee)),
          (this.reponsableWarehouse = "No lo obtiene el servicio"),
          (this.warehouseLatitude = this.wareHouseDetailArr.oLocation),
          (this.warehouseLongitude = this.wareHouseDetailArr.oLocation),
          this.getCountries();
          this.addMarker();

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    documentDetail() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministrator}/warehouses/${this.$route.params.id}/files`,
        {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
          params: {
            iItemsPerPage: this.iItemsPerPage,
            iPageNumber: this.iCurrentPage,
          },
        }
      )
        .then((response) => {
          this.documentDetailArr = response.data.results;
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },

    setDiscard() {
      this.modifyWarehouse = true;
      this.warehousesDetail();
    },

    setDocument() {
      this.tabs = 4;
    },
    downloadFile(sFileUrl) {
      window.open(sFileUrl, "_blank");
    },
    toReturn() {
      this.$store.commit("setTab", 0);
      this.$router.push("/admin/warehouse");
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    iTab() {
      return this.$store.state.iTab;
    },
    validateForm: function() {
      return (
        this.warehouseName !== "" &&
        this.wahrehouseAddress !== "" &&
        this.warehouseCountry !== "" &&
        this.warehouseState !== "" &&
        this.warehouseCity !== "" &&
        this.warehousePostalCode !== "" &&
        this.warehousePhoneNumber !== "" &&
        this.warehouseEmail !== "" &&
        this.reponsableWarehouse !== "" &&
        this.warehousePrice !== "" &&
        this.warehouseCustomsPrice !== "" 
      );
    },
  },
  watch: {
    refreshTable: function() {
      this.warehousesDetail();
      this.documentDetail();
    },
    iCurrentPage: function() {
      this.documentDetail();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.warehouseTexts[this.selectLanguage];
        this.warehousesDetail();
        this.documentDetail();
      }
    },
    tabs: function() {
      switch (this.tabs) {
        case 0:
          this.warehousesDetail();
          break;
        case 1:
          this.documentDetail();
          break;

        default:
          break;
      }
    },
  },
};
